const AesEncode=(word)=>{
    const CryptoJS = require('./crypto-js.min');//引入crypto
    const key = CryptoJS.enc.Utf8.parse('d887aed2239884eb') // 密钥
              const iv = CryptoJS.enc.Utf8.parse('b2df5feec9d499c9') // 密钥偏移量
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let encryptedBase64Data = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    return encodeURIComponent(encryptedBase64Data);
}

// export default getFormat
export default AesEncode